import React from 'react';
import cx from 'clsx';
import Typography from 'src/components/core/Typography';
import Clickable from 'src/components/core/Clickable';
import theme from 'src/theme';

interface Props {
  options: string[], // max 3
  activeIdx: number,
  onChange: (idx: number) => void,
}

const PlanIntervalTabs: React.FC<Props> = ({ options, activeIdx, onChange }) => (
  <div
    className="w-[400px] grid grid-cols-2 rounded-full"
    style={{
      background: 'rgba(0, 0, 0, 0.15)',
    }}
  >
    {options.map((option, i) => (
      <Clickable
        key={option}
        className={cx(
          activeIdx === i && 'text-blue bg-teal rounded-full',
          activeIdx !== i && 'text-white',
          'flex justify-center py-2',
        )}
        onClick={() => onChange(i)}
      >
        <Typography variant="p3" color="inherit">
          {option}
        </Typography>
      </Clickable>
    ))}
  </div>
);

export default PlanIntervalTabs;
